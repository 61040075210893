import { NextRouter } from 'next/router';
import Routes from '../routes.mjs';
import getJobsLocale from './getJobsLocale';
import replacePageName from './replacePageName';

const getLocalizedRoute = (
  routeName: string,
  locale: string,
) => {
  if (
    locale && [
      'company-profiles',
      'job',
      'jobs',
      'job-apply',
      'jobs-cities',
      'jobs-positions',
      'jobs-landing',
    ].includes(routeName)
  ) return `${routeName}.${getJobsLocale(locale)}`;

  const localizedRouteName = `${routeName}.${locale}`;

  const localizedRoute = (
    Routes as unknown as { routes: { name: string }[] }
  ).routes.find(({ name }) => name === localizedRouteName);

  return localizedRoute ? localizedRouteName : routeName;
};

const getPreviousRoute = () => {
  const savedRouter = localStorage.getItem('router');
  return savedRouter ? JSON.parse(savedRouter) : null;
};

const retrieveDestination = (
  fallbackRoute: string,
  fallbackQuery: Record<string, string>,
  locale: string,
) => {
  const previousRoute = getPreviousRoute();
  if (!previousRoute) {
    return { route: fallbackRoute, query: fallbackQuery };
  }

  return {
    route: getLocalizedRoute(replacePageName(previousRoute.route), locale),
    query: previousRoute.query,
  };
};

const clearRouterState = () => {
  localStorage.removeItem('router');
};

const saveRouterState = (router: Partial<NextRouter>) => {
  localStorage.setItem('router', JSON.stringify(router));
};

export {
  retrieveDestination,
  clearRouterState,
  saveRouterState,
  getPreviousRoute,
};
